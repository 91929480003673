import type {NextComponentType} from 'next';
import Link from 'next/link';

const GurucoolCTA: NextComponentType = () => {
	return (
		<section className='w-full bg-primary py-6'>
			<div className='innerDiv flex flex-row items-center justify-between gap-8 bg-primary'>
				<h2 className='sm:text-2xl text-lg sm:text-center lg:text-3xl font-medium text-dark'>Want to know about gurucool?</h2>
				<div className='hidden sm:block hover:scale-105  transition-all'>
					<Link href='/home'>
						<a className='py-3 px-6 bg-black text-white rounded-md '>Explore</a>
					</Link>
				</div>
				<Link href='/home'>
					<a className='sm:hidden block text-2xl'>
						<i className='fal fa-circle-arrow-right'></i>
					</a>
				</Link>
			</div>
		</section>
	);
};

export default GurucoolCTA;
